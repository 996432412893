export const addressFormatter = {
  methods:  {
    preferredAddress (preference, addresses) {
      return preference.map(kind =>
        addresses.find(obj => obj.kind === kind)
      ).find(address => address !== undefined)
    },
    formattedAddress(address) {
      if (!address?.line1) {
        return ''
      }

      if (address.line2) {
        return `${address.line1}, ${address.line2}, ${this.formatCityStateZip(address)}`
      }

      return `${address.line1}, ${this.formatCityStateZip(address)}`
    },
    formatCityStateZip(address) {
      const { city, state_province_region, zip_postal_code } = address

      if (city && state_province_region && zip_postal_code) {
        return `${address.city}, ${address.state_province_region} ${address.zip_postal_code}`
      }

      return ''
    },
  },
}
