<template>
  <b-modal id="address-settings-modal" hide-footer size="xl">
    <template slot="modal-title">
      <h3 class="p-0 m-0">
        Addresses
      </h3>
    </template>
    <address-settings />
  </b-modal>
</template>

<script>
  import AddressSettings from '@/components/AccountSettings/AddressSettings'

  export default {
    name: 'AddressSettingsModal',
    components: {
      AddressSettings,
    },
    props: {
      bus: Object,
    },
    async mounted() {
      this.bus.$on('address-settings-modal', this.open)
    },
    methods: {
      open() {
        this.$bvModal.show('address-settings-modal')
      },
    },
  }
</script>
