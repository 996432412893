var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    { attrs: { id: "address-settings-modal", "hide-footer": "", size: "xl" } },
    [
      _c("template", { slot: "modal-title" }, [
        _c("h3", { staticClass: "p-0 m-0" }, [
          _vm._v("\n      Addresses\n    "),
        ]),
      ]),
      _c("address-settings"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }